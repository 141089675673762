import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FwService } from '@services/fw.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

  typeSelected: string;

  personalData = {};
  step = 1;
  questions = [];
  answers = {
    correo: '',
    idEmpresa:'',
    empresa: '',
    fechaNacimiento: '',
    countryCode: '',
    filial: '',
    idGrupo:'',
    grupo: '',
    puesto: '',
    rangoIngresos: '',
    sexo: '',
    terminos: '',
    version: '',
    idioma: '',
    pais: '',
    lstPR: []
  }

  lang = '';
  company = '';
  idTest = 0;
  idParamExtra = 0;

  constructor(private fwServices: FwService,
    private router: Router,
    private spinnerService: NgxSpinnerService ) {
    this.typeSelected = 'ball-fussion';

    let _personalData = JSON.parse(localStorage.getItem('personalData'));
    this.company = _personalData.empresa;
    this.idTest = _personalData.idTest;
    this.idParamExtra = _personalData.idParamExtra;
    if (!_personalData) {
      this.router.navigate(['']);
    }
    this.answers.correo = _personalData.correo;
    this.answers.idEmpresa = _personalData.idEmpresa;
    this.answers.empresa = _personalData.empresa;
    this.answers.fechaNacimiento = _personalData.fechaNacimiento;
    this.answers.countryCode = _personalData.countryCode;
    this.answers.filial = _personalData.filial;
    this.answers.idGrupo = _personalData.idGrupo;
    this.answers.grupo = _personalData.grupo;
    this.answers.puesto = _personalData.puesto;
    this.answers.rangoIngresos = _personalData.rangoIngresos;
    this.answers.sexo = _personalData.sexo;
    this.answers.terminos = _personalData.terminos;
    this.answers.version = _personalData.version;
    this.answers.pais = _personalData.pais;
    this.answers.idioma = _personalData.idioma;
    this.lang = _personalData.idioma;
  }

  ngOnInit(): void {
    this.fwServices.getFw(this.lang, this.answers.countryCode)
      .subscribe(res => {
        res.lstPreguntas.map((element, key) => {
          res.lstPreguntas[key].pregunta.type = element.pregunta.infoAdicional.type;
          res.lstPreguntas[key].pregunta.disabled = element.pregunta.infoAdicional.disabled == 'true';
          res.lstPreguntas[key].pregunta.respuestaCliente = element.pregunta.infoAdicional.respuestaCliente;
          res.lstPreguntas[key].pregunta.respuestaClientetxt = element.pregunta.infoAdicional.respuestaClientetxt;
          element.respuestas.forEach((el, k) => {
            if (el.infoAdicional !== null) {
              res.lstPreguntas[key].respuestas[k].check = el.infoAdicional.check == 'true' ;
              res.lstPreguntas[key].respuestas[k].typeForm = el.infoAdicional.typeForm;
              res.lstPreguntas[key].respuestas[k].min = el.infoAdicional.min;
              res.lstPreguntas[key].respuestas[k].max = el.infoAdicional.max;
              if( res.lstPreguntas[key].pregunta.type == 'open' && el.infoAdicional){
                res.lstPreguntas[key].respuestas[k].txt = el.txt;
              } else if( res.lstPreguntas[key].pregunta.type == 'check' ) {
                res.lstPreguntas[key].respuestas[k].txt = el.txt;
              }
              res.lstPreguntas[key].respuestas[k].txt = el.txt;
              res.lstPreguntas[key].respuestas[k].complement = el.infoAdicional.complement;
            }
          });
        });
        this.questions = res.lstPreguntas;
      });
  }

  saveAnswer(answer, last) {
    this.spinnerService.show();

    let _arrAnswer = {
      idPregunta: '',
      lstRespuestas: [],
      ordenPregunta: ''
    }
    let _answer = {
      id: '',
      txtRespuesta: ''
    }

    switch (answer.pregunta.type) {
      case 'check':
        _arrAnswer.idPregunta = answer.pregunta.id;
        _arrAnswer.ordenPregunta = answer.pregunta.orden;
        answer.respuestas.forEach(element => {
          if (element.check) {
            _answer.id = element.id;
            _answer.txtRespuesta = element.respuestaCliente;
            _arrAnswer.lstRespuestas.push(_answer);
            _answer = {
              id: '',
              txtRespuesta: ''
            }
          }
        });
        break;
      case 'radio':
        _answer.id = answer.pregunta.respuestaCliente;
        _answer.txtRespuesta = null;
        _arrAnswer.idPregunta = answer.pregunta.id;
        _arrAnswer.ordenPregunta = answer.pregunta.orden;
        _arrAnswer.lstRespuestas.push(_answer);
        answer.pregunta.disabled = false;
        break;
      case 'open':
        let _ans = answer.respuestas.find(el => el.txt == answer.pregunta.respuestaCliente)
        _arrAnswer.idPregunta = answer.pregunta.id;
        _arrAnswer.ordenPregunta = answer.pregunta.orden;
        _answer.id = _ans.id;
        _answer.txtRespuesta = answer.pregunta.respuestaClientetxt;
        _arrAnswer.lstRespuestas.push(_answer);
        break;
      default:
        break;
    }
    // Guardamos la pregunta y sus respuestas en el arreglo
    this.answers.lstPR[answer.pregunta.orden - 1] = _arrAnswer;

    // Guardamos la pregunta y respuestas individual
    let _saveAnswer = {
      idTest: this.idTest,
      idParamExtra: this.idParamExtra,
      correo: this.answers.correo,
      empresa: this.answers.empresa,
      idEmpresa: this.answers.idEmpresa,
      idPrograma: this.answers.idGrupo,
      pais: this.answers.pais,
      idioma: this.lang,
      countryCode: this.answers.countryCode,
      finTest : last,
      pr: _arrAnswer
    };

  this.fwServices.saveAnswerFW( _saveAnswer )
    .subscribe( res => {
      if (last) {
        answer.pregunta.disabled = true;
        this.finishFW( res.data );
      } else {
        this.step = this.step + 1;
      }

      // Limpiamos el objeto
      _arrAnswer = {
        idPregunta: '',
        lstRespuestas: [],
        ordenPregunta: ''
      };

      this.spinnerService.hide();
    });
  }

  finishFW( res ) {
    let ansFW = res;
    let chart = {
      labels: [],
      data: []
    };
    res.calculo.lstCalculo.forEach(function (item) {
      chart.labels.push(item.categoriaDesc);
      chart.data.push(item.porcentajeCumplimiento);
    });
    let _score = {
      ansFW: ansFW,
      chart: chart
    }
    localStorage.setItem('dataReport', JSON.stringify(_score));
    this.router.navigate(['/report']);
  }

  checkValidation(question) {
    let _validation = question.respuestas.find(el => el.check)
    if (_validation) {
      let _index = this.questions.findIndex(element => element.pregunta.id == question.pregunta.id);
      this.questions[_index].pregunta.disabled = false;
    } else {
      let _index = this.questions.findIndex(element => element.pregunta.id == question.pregunta.id);
      this.questions[_index].pregunta.disabled = true;
    }
  }

  toHome() {
    localStorage.setItem('fromView', 'questions');

    this.router.navigateByUrl(
        '/?company=' + this.answers.empresa +
        '&group=' + this.answers.grupo +
        '&version=' + this.answers.version +
        '&idCompany=' + this.answers.idEmpresa +
        '&idGroup=' + this.answers.idGrupo +
        '&lang=' + this.answers.idioma );
  }


  changeAnswer(question, answer){
    question.pregunta.disabled = false;
    if(answer.infoAdicional.typeForm == 'range' || answer.infoAdicional.typeForm == 'text' ){
      answer.infoAdicional.showInput = 'true';
    } else {
      question.respuestas.map((el)=>{
        el.infoAdicional.showInput = 'false';
      });
    }
  }

}
