<div class="container-fluid" style="width: 1220px;">
    <div class="row">
        <div class="col-md-12 m-0 p-0 container">
            <img src="assets/img/portada_fw.png" alt="" width="100%">
            <div class="top-right">
                <img class="img-logo-modals-goals mt-0" src="assets/img/logo_blanco.png" alt="Logo"
                    style="width: 400px; margin-right: 30px;">
            </div>
            <div class="centered" *ngIf="lang == 'ES'">
                <p class="title">Bienestar Financiero</p>
                <p class="subtitle">Diagnóstico individual</p>
            </div>
            <div class="centered" *ngIf="lang == 'PT'">
                <p class="title">Bem-estar Financeiro</p>
                <p class="subtitle">Diagnóstico individual</p>
            </div>
            <div class="centered" *ngIf="lang == 'EN'">
                <p class="title">Financial Wellness</p>
                <p class="subtitle">Individual diagnosis</p>
            </div>
            <div class="bottom-right">
                <p>www.invested.mx</p>
            </div>
        </div>
    </div>
    <div class="row" style="page-break-before: always;"></div>
    <div class="row">
        <div class="col-10 mx-auto mt-5" *ngIf="lang == 'ES'">
            <br /><br />
            <p class="header-title mb-0">Bienestar Financiero</p>
            <p class="header-subtitle mt-0">Diagnóstico individual</p>
        </div>
        <div class="col-10 mx-auto mt-5" *ngIf="lang == 'PT'">
            <br /><br />
            <p class="header-title mb-0">Bem-estar financeiro</p>
            <p class="header-subtitle mt-0">Diagnóstico individual</p>
        </div>
        <div class="col-10 mx-auto mt-5" *ngIf="lang == 'EN'">
            <br /><br />
            <p class="header-title mb-0">Financial Wellness</p>
            <p class="header-subtitle mt-0">Individual diagnosis</p>
        </div>
        <div class="col-10 mx-auto bg-primary-blue text-white"
            style="margin-top: 80px; padding-left: 30px; padding-right: 30px;">
            <p class="mx-2 fs-3 text-justify fw-bolder" style="padding-top: 50px;" *ngIf="lang == 'ES'">Este reporte es
                un diagnóstico de Bienestar Financiero donde, de acuerdo
                a las respuestas que nos proporcionaste en el test, evaluamos 6 pilares
                que consideramos clave para alcanzar tu Bienestar Financiero:</p>
            <p class="mx-2 fs-3 text-justify fw-bolder" style="padding-top: 50px;" *ngIf="lang == 'PT'">Este relatório é
                um diagnóstico de Bem-Estar Financeiro onde, de acordo com as respostas que nos forneceu no teste,
                avaliamos 6 pilares que consideramos fundamentais para alcançar o seu Bem-Estar Financeiro:</p>
            <p class="mx-2 fs-3 text-justify fw-bolder" style="padding-top: 50px;" *ngIf="lang == 'EN'">This report is a diagnosis of Financial Wellness where, according to the answers you provided us in the test, we evaluate 6 pillars that we consider key to achieving your Financial Wellness:</p>
            <div class="row" *ngIf="lang == 'ES'">
                <div class="col-5 ml-2 mt-4">
                    <p class="mx-2 my-0 fs-3 fw-bolder">1. Disfruto el camino</p>
                    <p class="mx-2 my-0 fs-3 fw-bolder">2. Sé dónde estoy parado</p>
                    <p class="mx-2 my-0 fs-3 fw-bolder">3. Sé a dónde quiero llegar</p>
                </div>
                <div class="col-7 px-2 mb-5 mt-4">
                    <p class="mx-2 my-0 fs-3 fw-bolder">4. Gestiono correctamente mi dinero</p>
                    <p class="mx-2 my-0 fs-3 fw-bolder">5. Estoy protegido ante una eventualidad</p>
                    <p class="mx-2 my-0 fs-3 fw-bolder">6. Optimizo mi dinero</p>
                </div>
            </div>
            <div class="row" *ngIf="lang == 'PT'">
                <div class="col-5 ml-2 mt-4">
                    <p class="mx-2 my-0 fs-3 fw-bolder">1. Desfruto do caminho</p>
                    <p class="mx-2 my-0 fs-3 fw-bolder">2. Sei onde estou <br /> &nbsp;&nbsp;&nbsp;&nbsp;posicionado</p>
                    <p class="mx-2 my-0 fs-3 fw-bolder">3. Sei aonde quero chegar</p>
                </div>
                <div class="col-7 px-2 mb-5 mt-4">
                    <p class="mx-2 my-0 fs-3 fw-bolder">4. Gerencio meu dinheiro corretamente</p>
                    <p class="mx-2 my-0 fs-3 fw-bolder">5. Estou protegido diante de uma &nbsp;&nbsp;&nbsp;&nbsp;eventualidade</p>
                    <p class="mx-2 my-0 fs-3 fw-bolder">6. Otimizo meu dinheiro</p>
                </div>
            </div>
            <div class="row" *ngIf="lang == 'EN'">
                <div class="col-6 ml-2 mt-4">
                    <p class="mx-2 my-0 fs-3 fw-bolder">1. I enjoy the journey7</p>
                    <p class="mx-2 my-0 fs-3 fw-bolder">2. I know where I stand</p>
                    <p class="mx-2 my-0 fs-3 fw-bolder">3. I know where I want to get to</p>
                </div>
                <div class="col-6 px-2 mb-5 mt-4">
                    <p class="mx-2 my-0 fs-3 fw-bolder">4. I manage properly</p>
                    <p class="mx-2 my-0 fs-3 fw-bolder">5. I'm protected against contingencies</p>
                    <p class="mx-2 my-0 fs-3 fw-bolder">6. I optimize my money</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-10 mx-auto mt-5">
            <div class="row">
                <div class="col-4 ml-2 mt-4 mx-auto align-center">
                    <p class="text-center fc-blue fs-2 fw-bolder mt-4 mb-5" *ngIf="lang == 'ES'">Tu score global es</p>
                    <p class="text-center fc-blue fs-2 fw-bolder mt-4 mb-5" *ngIf="lang == 'PT'">Sua pontuação geral é</p>
                    <p class="text-center fc-blue fs-2 fw-bolder mt-4 mb-5" *ngIf="lang == 'EN'">Your global score is</p>
                    <p class="text-center mt-4 ml-4 fs-global-score fw-bolder"
                        [ngClass]="{'danger-l': result.calculo.scoreBienestarFinanciero <= 50, 'warning-l': result.calculo.scoreBienestarFinanciero > 50 && result.calculo.scoreBienestarFinanciero <= 89, 'ok-l': result.calculo.scoreBienestarFinanciero >= 90}">
                        {{result.calculo.scoreBienestarFinanciero}}%</p>
                    <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValue"
                        [centralLabel]="centralLabel" [options]="options"></rg-gauge-chart>
                </div>
                <div class="col-8 px-2 mb-5 mt-4 mx-auto align-center">
                    <p class="text-center fc-blue fs-2 fw-bolder mt-4 mb-5" *ngIf="lang == 'ES'">Score por pilar</p>
                    <p class="text-center fc-blue fs-2 fw-bolder mt-4 mb-5" *ngIf="lang == 'PT'">Pontuação do Pilar</p>
                    <p class="text-center fc-blue fs-2 fw-bolder mt-4 mb-5" *ngIf="lang == 'EN'">Pillar Score</p>
                    <div style="width: 650px; margin-top: 70px;">
                        <canvas baseChart [data]="barChartData" [options]="barChartOptions" [type]="barChartType"
                            [plugins]="barChartPlugins">
                        </canvas>
                    </div>
                </div>
            </div>
            <div style="border-top: 10px solid #F2A640; margin-top: 120px;"></div>
            <div class="row mt-4">
                <div class="col-12 text-center bg-white header-container mt-0">
                    <img class="mx-auto img-logo-modals-goals mt-0" src="assets/img/logo-invested.png" alt="Logo">
                    <h5 class="text-muted mt-3">1/3</h5>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="page-break-before: always;"></div>
    <div class="row">
        <div class="col-10 mx-auto mt-5" *ngIf="lang == 'ES'">
            <br /><br />
            <p class="header-title mb-0">Bienestar Financiero</p>
            <p class="header-subtitle mt-0">Diagnóstico individual</p>
        </div>
        <div class="col-10 mx-auto mt-5" *ngIf="lang == 'PT'">
            <br /><br />
            <p class="header-title mb-0">Bem-estar financeiro</p>
            <p class="header-subtitle mt-0">Diagnóstico individual</p>
        </div>
        <div class="col-10 mx-auto mt-5" *ngIf="lang == 'EN'">
            <br /><br />
            <p class="header-title mb-0">Financial Wellness</p>
            <p class="header-subtitle mt-0">Individual diagnosis</p>
        </div>
        <div class="row">
            <div class="col-10 mx-auto mt-5">
                <div class="row">
                    <div class="col-1">
                        <div class="bg-primary-blue" style="height: 100%; width: 30px; border-radius: 20px 20px;">
                        </div>
                    </div>
                    <div class="col-11" *ngIf="lang == 'ES'">
                        <p class="fs-4 text-justify">Al realizar el test y visitar este reporte, has dado el primer paso
                            para mejorar tu bienestar financiero.</p>
                        <p class="fs-4 text-justify">Es importante que tomes acción, hemos visto que el 90% de las
                            personas que toman
                            acción inmediata son quienes logra cumplir sus objetivos, si lo dejan para después
                            normalmente no los cumplen.</p>
                        <p class="fw-bolder fs-4 text-justify">¡Queremos que seas parte del porcentaje que sí los
                            cumple!</p>
                        <p class="fs-4 text-justify">Recuerda que las finanzas no tienen que ser complicadas, <span
                                class="fw-bolder">no olvides disfrutar el
                                camino</span>.</p>
                        <p class="fs-4 text-justify">Te compartimos algunas recomendaciones personalizadas por cada
                            pilar
                            evaluado:</p>
                    </div>
                    <div class="col-11" *ngIf="lang == 'PT'">
                        <p class="fs-4 text-justify">Ao fazer o teste e visitar este relatório, você deu o primeiro
                            passo para melhorar seu bem-estar financeiro.</p>
                        <p class="fs-4 text-justify">É importante que você tome uma atitude, vimos que 90% das pessoas
                            que agem imediatamente são aquelas que conseguem atingir seus objetivos, se deixarem para
                            depois geralmente não os cumprem.</p>
                        <p class="fw-bolder fs-4 text-justify">Queremos que você faça parte da porcentagem que os
                            atende!</p>
                        <p class="fs-4 text-justify">Lembre-se que as finanças não precisam ser complicadas, <span
                                class="fw-bolder">não se esqueça de curtir a estrada</span>.</p>
                        <p class="fs-4 text-justify">Compartilhamos algumas recomendações personalizadas para cada
                            pilar avaliado:</p>
                    </div>
                    <div class="col-11" *ngIf="lang == 'EN'">
                        <p class="fs-4 text-justify">By taking the test and visiting this report, you have taken the first step to improve your financial wellness.</p>
                        <p class="fs-4 text-justify">It is important that you take action, we have seen that 90% of the people who take immediate action are the ones who manage to achieve their goals, if they leave it for later they usually do not meet them.</p>
                        <p class="fw-bolder fs-4 text-justify">We want you to be part of the percentage that does achieve them!</p>
                        <p class="fs-4 text-justify">Remember that finances do not have to be complicated, <span
                                class="fw-bolder">don't forget to enjoy the journey</span>.</p>
                        <p class="fs-4 text-justify">We share some personalized recommendations for each evaluated pillar:</p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-6 mx-auto mx-2 mt-4">
                        <div class="row">
                            <div class="col-11 mx-auto mt-2">
                                <div class="row">
                                    <div class="col-2 text-center">
                                        <h1 class="text-left text-white fw-bolder mt-3"> <span
                                                class="bg-secondary-green rounded-circle p-2">
                                                <fa-icon [icon]="faShoePrints"></fa-icon>
                                            </span></h1>
                                    </div>
                                    <div class="col-10" style="padding-left: 0px;">
                                        <h3 class="px-3 py-1 bg-secondary-green rounded-pill text-white fw-bolder text-center"><span
                                                style="width: 100%;">
                                                {{disfruto.categoriaDesc}}: <br /> {{disfruto.porcentajeCumplimiento}}%</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-11 mx-auto mt-4" style="text-align: justify; font-size: 11pt; height: 410px;"
                                [innerHTML]="disfruto.text">
                            </div>
                        </div>
                    </div>
                    <div class="col-6 mx-auto mx-2 mt-4">
                        <div class="row">
                            <div class="col-11 mx-auto mt-2">
                                <div class="row">
                                    <div class="col-2 text-center">
                                        <h1 class="text-left text-white fw-bolder mt-3"> <span
                                                class="bg-secondary-green rounded-circle p-2">
                                                <fa-icon [icon]="faMapLocationDot"></fa-icon>
                                            </span></h1>
                                    </div>
                                    <div class="col-10" style="padding-left: 0px;">
                                        <h3 class="px-3 py-1 bg-secondary-green rounded-pill text-white fw-bolder text-center"><span
                                                style="width: 100%;">
                                                {{dondeEstoy.categoriaDesc}}: <br *ngIf="lang == 'EN'"/>{{dondeEstoy.porcentajeCumplimiento}}%</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-11 mx-auto mt-4"
                                style="text-align: justify; font-size: 11pt; height: 400px;"
                                [innerHTML]="dondeEstoy.text">
                            </div>
                        </div>
                    </div>
                </div>
                <div style="border-top: 10px solid #F2A640; margin-top: 150px;"></div>
                <div class="row mt-4">
                    <div class="col-12 text-center bg-white header-container mt-0">
                        <img class="mx-auto img-logo-modals-goals mt-0" src="assets/img/logo-invested.png" alt="Logo">
                        <h5 class="text-muted mt-3">2/3</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="page-break-before: always;"></div>
    <div class="row">
        <div class="col-10 mx-auto mt-5">
            <br /><br />
            <div *ngIf="lang == 'ES'">
                <p class="header-title mb-0">Bienestar Financiero</p>
                <p class="header-subtitle mt-0">Diagnóstico individual</p>
            </div>
            <div *ngIf="lang == 'PT'">
                <p class="header-title mb-0">Bem-estar financeiro</p>
                <p class="header-subtitle mt-0">Diagnóstico individual</p>
            </div>
            <div *ngIf="lang == 'EN'">
                <p class="header-title mb-0">Financial Wellness</p>
                <p class="header-subtitle mt-0">Individual diagnosis</p>
            </div>
            <div class="row">
                <div class="col-6 mx-auto mx-2 mt-4">
                    <div class="row">
                        <div class="col-11 mx-auto mt-2">
                            <div class="row">
                                <div class="col-2 text-center">
                                    <h1 class="text-left text-white fw-bolder mt-3"> <span
                                            class="bg-secondary-green rounded-circle p-2">
                                            <fa-icon [icon]="faPlaneArrival"></fa-icon>
                                        </span></h1>
                                </div>
                                <div class="col-10" style="padding-left: 0px;">
                                    <h3 class="px-3 py-1 bg-secondary-green rounded-pill text-white fw-bolder text-center"><span
                                            style="width: 100%;">
                                            {{dondellegar.categoriaDesc}}: {{dondellegar.porcentajeCumplimiento}}%</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-11 mx-auto mt-4"
                            style="text-align: justify; font-size: 11pt; height: 400px;"
                            [innerHTML]="dondellegar.text">
                        </div>
                    </div>
                </div>
                <div class="col-6 mx-auto mx-2 mt-4">
                    <div class="row">
                        <div class="col-11 mx-auto mt-2">
                            <div class="row">
                                <div class="col-2 text-center">
                                    <h1 class="text-left text-white fw-bolder mt-3"> <span
                                            class="bg-secondary-green rounded-circle px-3 py-2">
                                            <fa-icon [icon]="faThumbsUp"></fa-icon>
                                        </span></h1>
                                </div>
                                <div class="col-10" style="padding-left: 7px;">
                                    <h3 class="px-3 py-1 bg-secondary-green rounded-pill text-white fw-bolder text-center"><span
                                            style="width: 100%;">
                                            {{gestiono.categoriaDesc}}: <br *ngIf="lang == 'EN'"/>{{gestiono.porcentajeCumplimiento}}%</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-11 mx-auto mt-4" style="text-align: justify; font-size: 11pt; height: 410px;"
                            [innerHTML]="gestiono.text">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 mx-auto mx-2 mt-4">
                    <div class="row">
                        <div class="col-11 mx-auto mt-2">
                            <div class="row">
                                <div class="col-2 text-center">
                                    <h1 class="text-left text-white fw-bolder mt-3"> <span
                                            class="bg-secondary-green rounded-circle px-3 py-2">
                                            <fa-icon [icon]="faShieldHalved"></fa-icon>
                                        </span></h1>
                                </div>
                                <div class="col-10" style="padding-left: 7px;">
                                    <h3 class="px-3 py-1 bg-secondary-green rounded-pill text-white fw-bolder text-center"><span
                                            style="width: 100%;">
                                            {{protegido.categoriaDesc}}: {{protegido.porcentajeCumplimiento}}%</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-11 mx-auto mt-4" style="text-align: justify; font-size: 11pt; height: 410px;"
                            [innerHTML]="protegido.text">
                        </div>
                    </div>
                </div>
                <div class="col-6 mx-auto mx-2 mt-4">
                    <div class="row">
                        <div class="col-11 mx-auto mt-2">
                            <div class="row">
                                <div class="col-2 text-center">
                                    <h1 class="text-left text-white fw-bolder mt-3"> <span
                                            class="bg-secondary-green rounded-circle px-3 py-2">
                                            <fa-icon [icon]="faCoins"></fa-icon>
                                        </span></h1>
                                </div>
                                <div class="col-10" style="padding-left: 7px;">
                                    <h3 class="px-3 py-1 bg-secondary-green rounded-pill text-white fw-bolder text-center"><span
                                            style="width: 100%;">
                                            {{optimizo.categoriaDesc}}: <br /> {{optimizo.porcentajeCumplimiento}}%</span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-11 mx-auto mt-4" style="text-align: justify; font-size: 11pt; height: 410px;"
                            [innerHTML]="optimizo.text">
                        </div>
                    </div>
                </div>
                <div style="border-top: 10px solid #F2A640; margin-top: 90px;"></div>
                <div class="row mt-4">
                    <div class="col-12 text-center bg-white header-container mt-0">
                        <img class="mx-auto img-logo-modals-goals mt-0" src="assets/img/logo-invested.png" alt="Logo">
                        <h5 class="text-muted mt-3">3/3</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner type="ball-scale-multiple">
    <p style="color: #fff;">Cargando...</p>
</ngx-spinner>