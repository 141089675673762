import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

import { FwService } from '@services/fw.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  formPersonalData: FormGroup;
  faGlobe = faGlobe;
  idCompany='';
  company = '';
  idGroup = '';
  group = '';
  version = '';
  partner = '';
  countries = [];

  lang = '';

  genero = ['Masculino', 'Femenino', 'Prefiero no decirlo'];
  generoPT = ['Masculino', 'Feminino', 'Prefiro não dizer'];
  generoEN = ['Male', 'Female', 'I prefer not to say'];
  inputOptionsGenero = [];

  filialAlfa = ['Alfa Corporativo', 'Sigma', 'Nemak', 'Alpek', 'Axtel'];
  filialAlfaPT = ['Alfa Corporativo', 'Sigma', 'Nemak', 'Alpek', 'Axtel'];
  filialAlfaEN = ['Alfa Corporativo', 'Sigma', 'Nemak', 'Alpek', 'Axtel'];
  filialIngram = ['CEDIM', 'Guadalajara', 'León', 'Mérida', 'Monterrey', 'Parque Chapultepec', 'Puebla', 'Querétaro', 'San Diego', 'Tijuana'];
  filialIngramPT = ['CEDIM', 'Guadalajara', 'León', 'Mérida', 'Monterrey', 'Parque Chapultepec', 'Puebla', 'Querétaro', 'San Diego', 'Tijuana'];
  filialIngramEN = ['CEDIM', 'Guadalajara', 'León', 'Mérida', 'Monterrey', 'Parque Chapultepec', 'Puebla', 'Querétaro', 'San Diego', 'Tijuana'];
  filialGrupoParker = ['COR Mty', 'Parker FC Toluca y Mty', 'Parker Motion and Control', 'Parker Hidraulica', 'Parker Matamoros I', 'Parker Matamoros II', '320 - Parker Tijuana', 'Parker Legris', 'MEX GPD Planta Monterrey Hyd', 'Parker Chomerics Mty'];
  filialNatura = [ 'Avon', 'Natura', 'The Body Shop', 'NAtura & Co' ];
  filialHeineken = [ 'Confianza', 'Sindicalizado'];
  inputOptionsFilial = [];

  levelOrganizatinal = ['Director', 'Gerente', 'Jefatura, Analista, Administrativo', 'Operativo'];
  levelOrganizatinalPT = ['Diretor', 'Gerente', 'Chefe, Analista, Administrativo', 'Operacional'];
  levelOrganizatinalEN = ['Director', 'Manager', 'Leadership, Analyst, Administrative', 'Operative'];
  levelOrganizatinalNatura = [ 'Analista', 'Coordinador', 'Gerente', 'Director', 'Fuerza de Ventas', 'Equipo de Tienda' ];
  inputOptionsLevelOrganizatinal = [];

  income = ['Menor a $20,000', '$20,000 a $50,000', '$50,000 a $100,000', '$100,000 a $150,000', 'Mayor a $150,000'];
  incomePT = ['Menos de $20,000', '$20,000 a $50,000', '$50,000 a $100,000', '$100,000 a $150,000', 'Acima de $150,000'];
  incomeEN = ['Less than $20,000', '$20,000 to $50,000', '$50,000 to $100,000', '$100,000 to $150,000', 'Greater than $150,000'];
  inputOptionsIncome = [];

  language_es = [
    { id: 'ES',
      text: 'Español'
    },
    { id: 'PT',
      text: 'Portugués'
    },
    { id: 'EN',
      text: 'Inglés'
    },
  ];

  language_pt = [
    { id: 'ES',
      text: 'Espanhol'
    },
    { id: 'PT',
      text: 'Português'
    },
    { id: 'EN',
      text: 'Inglês'
    },
  ];

  language_en = [
    { id: 'ES',
      text: 'Spanish'
    },
    { id: 'PT',
      text: 'Portuguese'
    },
    { id: 'EN',
      text: 'English'
    },
  ];

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private fwServices: FwService,) {
    this.formPersonalData = this.fb.group({
      'mail': ['', Validators.compose([
                Validators.required,
                Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
              ])],
      'nacimiento': ['', Validators.compose([
                Validators.required
              ])],
      'countryCode': ['', Validators.compose([
                Validators.required
              ])],
      'sexo': ['', Validators.compose([
                Validators.required
              ])],
      'filial': [''],
      'puesto': ['', Validators.compose([
                Validators.required
              ])],
      'ingreso': ['', Validators.compose([
                Validators.required
              ])],
      'terminos': [false, Validators.compose([
                  Validators.required
                ])]
    })

    if((this.company == 'Alfa Corporativo' && this.group == 'Estudio FW') || this.company == 'Ingram' || this.company == 'Grupo Parker') {
      this.formPersonalData.get('filial').setValidators(Validators.required);
    }
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.idGroup = params.idGroup;
        this.group = params.group;
        this.idCompany = params.idCompany;
        this.company = params.company;
        this.version = params.version;
        this.lang = params.lang ? params.lang : 'ES' ;
        this.partner = params.partner ? params.partner : '';
      }
    );
    this.countries = [
      {
          "nameEs": "Antigua & Barbuda",
          "nameEn": "Antigua And Barbuda",
          "code": "AG"
      },
      {
          "nameEs": "Aruba",
          "nameEn": "Aruba",
          "code": "AW"
      },
      {
          "nameEs": "Bahamas",
          "nameEn": "Bahamas",
          "code": "BS"
      },
      {
          "nameEs": "Barbados",
          "nameEn": "Barbados",
          "code": "BB"
      },
      {
          "nameEs": "Bolivia",
          "nameEn": "Bolivia",
          "code": "BO"
      },
      {
          "nameEs": "Brasil",
          "nameEn": "Brazil",
          "code": "BR"
      },
      {
          "nameEs": "Chile",
          "nameEn": "Chile",
          "code": "CL"
      },
      {
          "nameEs": "Colombia",
          "nameEn": "Colombia",
          "code": "CO"
      },
      {
          "nameEs": "Costa Rica",
          "nameEn": "Costa Rica",
          "code": "CR"
      },
      {
          "nameEs": "Ecuador",
          "nameEn": "Ecuador",
          "code": "EC"
      },
      {
          "nameEs": "El Salvador",
          "nameEn": "El Salvador",
          "code": "SV"
      },
      {
          "nameEs": "Guatemala",
          "nameEn": "Guatemala",
          "code": "GT"
      },
      {
          "nameEs": "Honduras",
          "nameEn": "Honduras",
          "code": "HN"
      },
      {
          "nameEs": "Jamaica",
          "nameEn": "Jamaica",
          "code": "JM"
      },
      {
          "nameEs": "México",
          "nameEn": "Mexico",
          "code": "MX"
      },
      {
          "nameEs": "Nicaragua",
          "nameEn": "Nicaragua",
          "code": "NI"
      },
      {
          "nameEs": "Panamá",
          "nameEn": "Panama",
          "code": "PA"
      },
      {
          "nameEs": "Paraguay",
          "nameEn": "Paraguay",
          "code": "PY"
      },
      {
          "nameEs": "Perú",
          "nameEn": "Peru",
          "code": "PE"
      },
      {
          "nameEs": "Puerto Rico",
          "nameEn": "Puerto Rico",
          "code": "PR"
      },
      {
          "nameEs": "República Dominicana",
          "nameEn": "Dominican Republic",
          "code": "DO"
      },
      {
          "nameEs": "Saint Lucia",
          "nameEn": "Saint Lucia",
          "code": "LC"
      },
      {
          "nameEs": "Trinidad & Tobago",
          "nameEn": "Trinidad And Tobago",
          "code": "TT"
      },
      {
          "nameEs": "Uruguay",
          "nameEn": "Uruguay",
          "code": "UY"
      },
      {
          "nameEs": "Venezuela",
          "nameEn": "Venezuela",
          "code": "VE"
      }
    ];
    this.inputOptionsGenero = this.genero;
    this.inputOptionsFilial = this.company == 'Alfa Corporativo' ? this.filialAlfa :
        this.company == 'Ingram' ? this.filialIngram :
        this.company == 'Grupo Parker' ? this.filialGrupoParker :
        this.company == 'Natura' ? this.filialNatura :
        this.company == 'Heineken' ? this.filialHeineken : [];

    this.inputOptionsLevelOrganizatinal = this.company == 'Natura' ? this.levelOrganizatinalNatura : this.levelOrganizatinal;
    this.inputOptionsIncome = this.income;
    this.fromView();
  }

  fromView(){
    if( localStorage.getItem('fromView')){
      let _personalData = JSON.parse(localStorage.getItem('personalData'));
      this.setLanguage(_personalData.idioma);
      this.formPersonalData.controls['mail'].setValue(_personalData.correo);
      this.formPersonalData.controls['nacimiento'].setValue(_personalData.fechaNacimiento);
      this.formPersonalData.controls['countryCode'].setValue(_personalData.countryCode);
      this.formPersonalData.controls['sexo'].setValue(_personalData.sexo);
      this.formPersonalData.controls['filial'].setValue(_personalData.filial);
      this.formPersonalData.controls['puesto'].setValue(_personalData.puesto);
      this.formPersonalData.controls['ingreso'].setValue(_personalData.rangoIngresos);
      this.formPersonalData.controls['terminos'].setValue(_personalData.terminos);
    } else {
      localStorage.clear();
    }
  }

  sendPerdonalData(){
    let idTest = localStorage.getItem('personalData') ? JSON.parse(localStorage.getItem('personalData')).idTest : null;
    let idParamExtra = localStorage.getItem('personalData') ? JSON.parse(localStorage.getItem('personalData')).idParamExtra : null;
    let _pais = this.countries.find(el => el.code == this.formPersonalData.controls['countryCode'].value);
    let _personalData = {
      correo: this.formPersonalData.controls['mail'].value,
      idEmpresa: this.idCompany,
      empresa: this.company,
      fechaNacimiento: this.formPersonalData.controls['nacimiento'].value,
      countryCode: this.formPersonalData.controls['countryCode'].value,
      filial: this.formPersonalData.controls['filial'].value,
      idGrupo:this.idGroup,
      grupo: this.group,
      puesto: this.formPersonalData.controls['puesto'].value,
      rangoIngresos: this.formPersonalData.controls['ingreso'].value,
      sexo: this.formPersonalData.controls['sexo'].value,
      terminos: this.formPersonalData.controls['terminos'].value,
      version: this.version,
      idioma: this.lang,
      pais: _pais ? _pais.nameEs : '',
      idTest : idTest,
      idParamExtra : idParamExtra,
      tipoMoneda: 'MXN'
    }

    // Validamos si ya tenemos idTest y idParamExtra
    // En dado caso de no contar con la info, realizamos el save
    if( idTest === null || idParamExtra === null ) {
      this.fwServices.saveExtraParamsFW( _personalData )
        .subscribe( res => {
          _personalData.idTest = res.data.idTest;
          _personalData.idParamExtra = res.data.idParamExtra;
          localStorage.setItem( 'personalData', JSON.stringify(_personalData));
          this.router.navigate(['/questions']);
        });
    } else {
      // FALTA VALIDAR SI ES NECESARIO COMPARAR LOS CORREOS PARA VOLVER A GUARDAR ***
      localStorage.setItem( 'personalData', JSON.stringify(_personalData));
      this.router.navigate(['/questions']);
    }
  }

  resetForm(){
    this.formPersonalData.reset({
      mail: '',
      nacimiento: '',
      countryCode: '',
      filial: '',
      ingreso: '',
      sexo: '',
      puesto: '',
      terminos: false
    });
  }

  setLanguage( lang ){
    switch (lang) {
      case 'ES':
        this.lang = 'ES';
        this.inputOptionsGenero = this.genero;
        this.inputOptionsFilial = this.company == 'Alfa Corporativo' ? this.filialAlfa : this.company == 'Ingram' ? this.filialIngram : this.company == 'Grupo Parker' ? this.filialGrupoParker : [];
        this.inputOptionsLevelOrganizatinal = this.levelOrganizatinal;
        this.inputOptionsIncome = this.income;
        if( localStorage.getItem('fromView')){
          localStorage.removeItem('fromView');
        } else {
          setTimeout(() => {
            this.resetForm();
          }, 20);
        }
        break;
      case 'PT':
        this.lang = 'PT';
        this.inputOptionsGenero = this.generoPT;
        this.inputOptionsFilial = this.company == 'Alfa Corporativo' ? this.filialAlfaPT : this.company == 'Ingram' ? this.filialIngramPT : this.company == 'Grupo Parker' ? this.filialGrupoParker : [];
        this.inputOptionsLevelOrganizatinal = this.levelOrganizatinalPT;
        this.inputOptionsIncome = this.incomePT;
        if( localStorage.getItem('fromView')){
          localStorage.removeItem('fromView');
        } else {
          setTimeout(() => {
            this.resetForm();
          }, 20);
        }
        break;
      case 'EN':
        this.lang = 'EN';
        this.inputOptionsGenero = this.generoEN;
        this.inputOptionsFilial = this.company == 'Alfa Corporativo' ? this.filialAlfaEN : this.company == 'Ingram' ? this.filialIngramEN : this.company == 'Grupo Parker' ? this.filialGrupoParker : [];
        this.inputOptionsLevelOrganizatinal = this.levelOrganizatinalEN;
        this.inputOptionsIncome = this.incomeEN;
        if( localStorage.getItem('fromView')){
          localStorage.removeItem('fromView');
        } else {
          setTimeout(() => {
            this.resetForm();
          }, 20);
        }
        break;
      default:
        break;
    }
  }
}
