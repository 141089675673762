import { environment } from "@environment/environment";

export const api_constants = {
    FW: {
        GET_FW: environment.baseURL + '/cqa/anon/v2/financialwellness',
        SAVE_FW: environment.baseURL + '/cqa/anon/v2_1/financialwellness',
        SAVE_QUESTIONS_OXXO: environment.baseURL + '/cqa/anon/oxxo/fw',
        GET_COUNTRIES: environment.baseToolsURL + '/country',
        INTERACTIVE: environment.baseURL + '/cqa/anon/v2_1/fw/interactivo',
    }
};
