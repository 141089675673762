<header class="header bg-dark py-2 d-flex align-items-stretch border-bottom border-dark">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-12 my-auto">
                <img class="img-fluid" src="/assets/img/logo_blanco.png">
            </div>
            <div class="col-md-3 col-sm-12 my-auto" *ngIf="company == 'Aon'">
                <img class="img-fluid mt-2" src="/assets/img/aon_logo.png" width="150px">
            </div>
        </div>
    </div>
</header>
<div class="container-fluid">
    <div class="row">
        <div class="row my-2" style="justify-content: right;">
            <div class="col-md-3">
                <div class="progress" style="height: 15px;">
                    <div class="progress-bar" role="progressbar" [ngStyle]="{'width': (step*100)/questions.length + '%'}" aria-valuenow="0" aria-valuemin="0" aria-valuemax="questions.length">{{step}} / {{questions.length}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngFor="let question of questions; index as $index; first as isFirst; last as isLast">
        <div class="my-2" *ngIf="step == question.pregunta.orden">
            <div class="animate__animated animate__bounceInUp" *ngIf="question.pregunta.type == 'check'">
                <div class="row">
                    <div class="col-md-8 mt-2 mx-auto text-center">
                        <p class="mb-0 text-center fw-bolder">{{ question.pregunta.txt }}</p>
                    </div>
                </div>
                <div class="d-grid gap-2 col-md-4 mx-auto my-2" *ngFor="let i of question.respuestas; index as $i">
                    <input type="checkbox" class="btn-check" name="options-step-{{$index}}-{{$i}}" id="options-step-{{$index}}-{{$i}}"
                        autocomplete="off" [(ngModel)]="i.check" (change)="checkValidation(question)" required>
                    <label class="btn btn-outline-primary btn-modal-step2 p-3 mb-2" for="options-step-{{$index}}-{{$i}}">
                        {{i.txt}}</label>
                        <div class="mb-3 text-center" *ngIf="i.check && i.infoAdicional?.showInput">
                        <input type="text" class="form-control" id="exampleInput"
                            [(ngModel)]="i.respuestaCliente" autocomplete="off" aria-describedby="other">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mx-auto my-2">
                        <div class="row" *ngIf="lang == 'ES'">
                            <div class="col-6 px-1">
                                <div class="d-grid gap-2">
                                    <button class="btn btn-secondary" type="button" *ngIf="isFirst" (click)="toHome()">Anterior</button>
                                    <button class="btn btn-secondary" type="button" *ngIf="!isFirst" (click)="step = step-1">Anterior</button>
                                </div>
                            </div>
                            <div class="col-6 px-1">
                                <div class="d-grid gap-2">
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="!isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Siguiente</button>
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Terminar</button>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="lang == 'PT'">
                            <div class="col-6 px-1">
                                <div class="d-grid gap-2">
                                    <button class="btn btn-secondary" type="button" *ngIf="isFirst" (click)="toHome()">Voltar</button>
                                    <button class="btn btn-secondary" type="button" *ngIf="!isFirst" (click)="step = step-1">Voltar</button>
                                </div>
                            </div>
                            <div class="col-6 px-1">
                                <div class="d-grid gap-2">
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="!isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Próximo</button>
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Terminar</button>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="lang == 'EN'">
                            <div class="col-6 px-1">
                                <div class="d-grid gap-2">
                                    <button class="btn btn-secondary" type="button" *ngIf="isFirst" (click)="toHome()">Back</button>
                                    <button class="btn btn-secondary" type="button" *ngIf="!isFirst" (click)="step = step-1">back</button>
                                </div>
                            </div>
                            <div class="col-6 px-1">
                                <div class="d-grid gap-2">
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="!isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Next</button>
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Finish</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="animate__animated animate__bounceInUp" *ngIf="question.pregunta.type == 'radio'">
                <div class="row">
                    <div class="col-md-8 mt-2 mx-auto text-center">
                        <p class="mb-0 text-center fw-bolder">{{ question.pregunta.txt }}</p>
                    </div>
                </div>
                <div class="d-grid gap-2 col-md-4 mx-auto my-2" *ngFor="let i of question.respuestas; index as $i">
                    <input type="radio" class="btn-check" name="options-step-1" id="options-step-{{$i+1}}"
                        autocomplete="off" [value]="i.id" *ngIf="!isLast"
                        [(ngModel)]="question.pregunta.respuestaCliente"
                        (change)="saveAnswer(question, isLast)"
                        required>
                    <input type="radio" class="btn-check" name="options-step-1" id="options-step-{{$i+1}}"
                        autocomplete="off" [value]="i.id" *ngIf="isLast"
                        [(ngModel)]="question.pregunta.respuestaCliente"
                        (change)="question.pregunta.disabled = false"
                        required>
                    <label class="btn btn-outline-primary btn-modal-step2 p-3 mb-2" for="options-step-{{$i+1}}">
                        {{i.txt}}</label>
                </div>
                <div class="row">
                    <div class="col-md-4 mx-auto my-2">
                        <div class="row" *ngIf="lang == 'ES'">
                            <div class="col-6 px-1">
                                <div class="d-grid gap-2">
                                    <button class="btn btn-secondary" type="button" *ngIf="isFirst" (click)="toHome()">Anterior</button>
                                    <button class="btn btn-secondary" type="button" *ngIf="!isFirst" (click)="step = step-1">Anterior</button>
                                </div>
                            </div>
                            <div class="col-6 px-1">
                                <div class="d-grid gap-2">
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="!isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Siguiente</button>
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Terminar</button>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="lang == 'PT'">
                            <div class="col-6 px-1">
                                <div class="d-grid gap-2">
                                    <button class="btn btn-secondary" type="button" *ngIf="isFirst" (click)="toHome()">Voltar</button>
                                    <button class="btn btn-secondary" type="button" *ngIf="!isFirst" (click)="step = step-1">Voltar</button>
                                </div>
                            </div>
                            <div class="col-6 px-1">
                                <div class="d-grid gap-2">
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="!isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Próximo</button>
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Terminar</button>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="lang == 'EN'">
                            <div class="col-6 px-1">
                                <div class="d-grid gap-2">
                                    <button class="btn btn-secondary" type="button" *ngIf="isFirst" (click)="toHome()">Back</button>
                                    <button class="btn btn-secondary" type="button" *ngIf="!isFirst" (click)="step = step-1">Back</button>
                                </div>
                            </div>
                            <div class="col-6 px-1">
                                <div class="d-grid gap-2">
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="!isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Next</button>
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Finish</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="animate__animated animate__bounceInUp" *ngIf="question.pregunta.type == 'open'">
                <div class="row">
                    <div class="col-md-8 mt-2 mx-auto text-center">
                        <p class="mb-0 text-center fw-bolder">{{ question.pregunta.txt }}</p>
                    </div>
                </div>
                <div class="d-grid gap-2 col-md-4 mx-auto my-2" *ngFor="let i of question.respuestas; index as $i">
                    <input type="radio" class="btn-check" name="options-step-1" id="options-step-{{$i+1}}"
                        autocomplete="off" [value]="i.txt"
                        [(ngModel)]="question.pregunta.respuestaCliente"
                        (change)="changeAnswer(question, i)"
                        required>
                    <label class="btn btn-outline-primary btn-modal-step2 p-3 mb-2" for="options-step-{{$i+1}}">
                        {{i.txt}}</label>
                    <div class="row">
                        <div class="col-md-12 mx-auto" *ngIf="i.typeForm == 'range' && i.infoAdicional?.showInput == 'true'">
                            <div class="row">
                                <div class="d-grid gap-2 col-12 mx-auto my-2">
                                    <div class="d-flex justify-content-between">
                                        <p></p>
                                        <h6 class="fc-blue labelRange"><span class="fw-bolder">{{question.pregunta.respuestaClientetxt}}</span> {{i.complement}}</h6>
                                    </div>
                                    <div class="mb-3 text-center">
                                            <input type="range" min="{{i.min}}" max="{{i.max}}" step="1"
                                                class="form-control-range mt-2 w-100 range-input" id="formControlRange"
                                                [(ngModel)]="question.pregunta.respuestaClientetxt">
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row">
                                <div class="col-md-6 px-1">
                                    <input type="range" min="{{i.min}}" max="{{i.max}}" step="1"
                                        class="form-control-range mt-2 w-100" id="formControlRange"
                                        [(ngModel)]="question.pregunta.respuestaClientetxt">
                                </div>
                                <div class="col-md-6 px-1">
                                    <h3>{{question.pregunta.respuestaClientetxt}} {{i.complement}}</h3>
                                </div>
                            </div> -->
                        </div>
                        <div class="col-md-12 mx-auto" *ngIf="i.typeForm == 'text' && i.infoAdicional?.showInput == 'true'">
                            <div class="mb-3 text-center">
                                <input type="text" class="form-control" id="exampleInput"
                                    [(ngModel)]="question.pregunta.respuestaClientetxt" autocomplete="off" aria-describedby="other">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mx-auto my-2">
                        <div class="row" *ngIf="lang == 'ES'">
                            <div class="col-6 px-1 mx-auto">
                                <div class="d-grid gap-2">
                                    <button class="btn btn-secondary" type="button" *ngIf="isFirst" (click)="toHome()">Anterior</button>
                                    <button class="btn btn-secondary" type="button" *ngIf="!isFirst" (click)="step = step-1">Anterior</button>
                                </div>
                            </div>
                            <div class="col-6 px-1 mx-auto">
                                <div class="d-grid gap-2">
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="!isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Siguiente</button>
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Terminar</button>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="lang == 'PT'">
                            <div class="col-6 px-1 mx-auto">
                                <div class="d-grid gap-2">
                                    <button class="btn btn-secondary" type="button" *ngIf="isFirst" (click)="toHome()">Voltar</button>
                                    <button class="btn btn-secondary" type="button" *ngIf="!isFirst" (click)="step = step-1">Voltar</button>
                                </div>
                            </div>
                            <div class="col-6 px-1 mx-auto">
                                <div class="d-grid gap-2">
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="!isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Próximo</button>
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Terminar</button>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="lang == 'EN'">
                            <div class="col-6 px-1 mx-auto">
                                <div class="d-grid gap-2">
                                    <button class="btn btn-secondary" type="button" *ngIf="isFirst" (click)="toHome()">Back</button>
                                    <button class="btn btn-secondary" type="button" *ngIf="!isFirst" (click)="step = step-1">Back</button>
                                </div>
                            </div>
                            <div class="col-6 px-1 mx-auto">
                                <div class="d-grid gap-2">
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="!isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Next</button>
                                    <button class="btn text-white bg-primary-blue" type="button" *ngIf="isLast" (click)="saveAnswer(question, isLast)" [disabled]="question.pregunta.disabled">Finish</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner size="medium" [type]="typeSelected"></ngx-spinner>
