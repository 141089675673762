import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '@components/home/home.component';
import { QuestionsComponent } from '@components/questions/questions.component';
import { ReportComponent } from '@components/report/report.component'
import { PrintComponent } from '@components/print/print.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'questions', component: QuestionsComponent },
  { path: 'report', component: ReportComponent },
  { path: 'print', component: PrintComponent },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
