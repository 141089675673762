import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartType } from 'chart.js';
import { faSquare, faShoePrints, faPlaneArrival, faCoins, faMapLocationDot, faThumbsUp, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  public barChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          maxRotation: 30,
          minRotation: 30,
          font: {
            size: 9,
          },
          color: "#004150"
        },
      },
      y: {
        min: 10,
        max: 130,
        display: false
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value, ctx) => {
          let percentage = value + "%";
          return percentage;
        },
        color: '#004150',
        font: {
          size: 25,
        },
      }
    }
  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [
    DataLabelsPlugin
  ];
  public barChartData = {
    labels: [],
    datasets: [
      { data: [], backgroundColor: '#F2A640' }
    ]
  };
  result: any = {};
  faSquare = faSquare;
  faShoePrints = faShoePrints;
  faPlaneArrival = faPlaneArrival;
  faCoins = faCoins;
  faMapLocationDot = faMapLocationDot;
  faThumbsUp = faThumbsUp;
  faShieldHalved = faShieldHalved;
  lang = '';
  company = '';

  constructor( private router: Router ) { }

  ngOnInit(): void {
    let _dataReport = JSON.parse(localStorage.getItem('dataReport'));
    let _personalData = JSON.parse(localStorage.getItem('personalData'));
    _dataReport.ansFW.calculo.lstCalculo.forEach((value, index) => {
      if( value.categoriaDesc === 'Disfruto el camino' ){
        _dataReport.ansFW.calculo.lstCalculo[index].img = 'https://dashfw.invested.mx/assets/img/disfruto_v2.png';
      }
      if( value.categoriaDesc === 'Sé dónde estoy parado' ){
        _dataReport.ansFW.calculo.lstCalculo[index].img = 'https://dashfw.invested.mx/assets/img/donde_v2.png';
      }
      if( value.categoriaDesc === 'Sé a dónde quiero llegar' ){
        _dataReport.ansFW.calculo.lstCalculo[index].img = 'https://dashfw.invested.mx/assets/img/llegar_v2.png';
      }
      if( value.categoriaDesc === 'Gestiono correctamente mi dinero' ){
        _dataReport.ansFW.calculo.lstCalculo[index].img = 'https://dashfw.invested.mx/assets/img/gestiono_v2.png';
      }
      if( value.categoriaDesc === 'Estoy protegido ante una eventualidad' ){
        _dataReport.ansFW.calculo.lstCalculo[index].img = 'https://dashfw.invested.mx/assets/img/protegido_v2.png';
      }
      if( value.categoriaDesc === 'Optimizo mi dinero' ){
        _dataReport.ansFW.calculo.lstCalculo[index].img = 'https://dashfw.invested.mx/assets/img/optimizo_v2.png';
      }

      console.log( _dataReport.ansFW.calculo.lstCalculo );
    });
    this.company = _personalData.empresa;
    this.result = _dataReport.ansFW;
    this.lang = _personalData.idioma;
    this.barChartData.datasets[0].data = _dataReport.chart.data;
    this.barChartData.labels = _dataReport.chart.labels;

    setTimeout(() => {
      const button_pill_0 = document.getElementById('v-pills-tab-0');
      const tab_0 = document.getElementById('tab-0');
      button_pill_0.classList.add('active');
      tab_0.classList.add('active');
    }, 100);
  }

  print(){
    this.router.navigate(['/print']);
  }

}
