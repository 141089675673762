<header class="header bg-dark py-2 d-flex align-items-stretch border-bottom border-dark">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-12 my-auto">
                <img class="img-fluid" src="/assets/img/logo_blanco.png">
            </div>
            <div class="col-md-3 col-sm-12 my-auto" *ngIf="company == 'Aon'">
                <img class="img-fluid mt-2" src="/assets/img/aon_logo_signature_red_rgb.png" style="height: 100px;">
            </div>
        </div>
    </div>
</header>
<div class="container mt-5">
  <div class="row mb-4">
    <div class="col-md-8 mx-auto">
      <div class="card bg-primary-green txt-white">
        <div class="card-body">
          <h4 class="my-1">¡Estas listo!</h4>
          <p class="my-1">Gracias, has dado el primer paso para alcanzar tu bienestar financiero.</p>
          <p class="my-1">Es importante que tomes acción, hemos visto que el 90% de las personas que toman
            acción inmediata son quienes logran cumplir sus objetivos,
            quienes lo dejan para después normalmente no los cumplen.</p>
          <p class="my-1">¡Queremos que seas parte del porcentaje que sí los cumple!.</p>
          <p class="my-1">Recuerda que las finanzas no tienen que ser complicadas, <span class="fw-bolder">no
                                            olvides disfrutar el camino.</span></p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8 mx-auto">
      <div class="row" *ngFor="let i of result.calculo.lstCalculo">
        <div class="col-md-12">
          <div class="card mb-4">
            <div class="ratio ratio-4x3 d-md-none d-lg-none d-xl-none">
              <img [src]="i.img" class="card-img-top" alt="...">
            </div>
            <div class="card-body">
              <h3 class="text-center">
                {{i.categoriaDesc}}
              </h3>
              <h5 class="text-center mt-3">{{i.porcentajeCumplimiento }}% de 100%</h5>
              <div class="progress mt-3 mb-4" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar"
                     [ngClass]="{'bg-danger-l': i.color === 'rojo', 'bg-warning-l': i.color === 'amarillo', 'bg-ok-l': i.color === 'verde'}"
                     style="width: {{i.porcentajeCumplimiento}}%"></div>
              </div>
              <div [innerHTML]="i.text" style="text-align: justify;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--
<div class="container-fluid">
    <div class="row">
        <div class="col-md-10 mx-auto">
            <div class="row">
                <div class="col-md-6 mx-auto m-2">
                    <div class="row">
                        <div class="col-10 mx-auto border" style="height: 350px;">
                            <canvas baseChart style="margin-top: 50px;"
                                [data]="barChartData"
                                [options]="barChartOptions" [type]="barChartType"
                                [plugins]="barChartPlugins">
                            </canvas>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mx-auto bg-primary-green m-2 text-white">
                    <div class="row mt-4" *ngIf="lang == 'ES'">
                        <div class="col-10 mx-auto mt-0" style="text-align: justify;">
                            <div class="row">
                                <div class="col-12" style="text-align: justify;">
                                    <h4 class="my-1">¡Estas listo!</h4>
                                    <p class="my-1">Gracias, has dado el primer paso para alcanzar tu bienestar financiero.</p>
                                    <p class="my-1">Es importante que tomes acción, hemos visto que el 90% de las personas que toman
                                        acción inmediata son quienes logran cumplir sus objetivos,
                                        quienes lo dejan para después normalmente no los cumplen.</p>
                                    <p class="my-1">¡Queremos que seas parte del porcentaje que sí los cumple!.</p>
                                    <p class="my-1">Recuerda que las finanzas no tienen que ser complicadas, <span class="fw-bolder">no
                                            olvides disfrutar el camino.</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4" *ngIf="lang == 'PT'">
                        <div class="col-10 mx-auto mt-0" style="text-align: justify;">
                            <div class="row">
                                <div class="col-12" style="text-align: justify;">
                                    <h4 class="my-1">Estás pronto!</h4>
                                    <p class="my-1">Obrigado, você deu o primeiro passo para alcançar seu bem-estar financeiro.</p>
                                    <p class="my-1">É importante que você tome medidas, vimos que 90% das pessoas que tomam medidas imediatas são as que conseguem cumprir seus objetivos, os que deixam para depois geralmente não os cumprem.</p>
                                    <p class="my-1">Queremos que você faça parte da porcentagem que os cumpre!.</p>
                                    <p class="my-1">Lembre-se que as finanças não têm que ser complicadas, <span class="fw-bolder">não se esqueça de desfrutar do caminho.</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4" *ngIf="lang == 'EN'">
                        <div class="col-10 mx-auto mt-0" style="text-align: justify;">
                            <div class="row">
                                <div class="col-12" style="text-align: justify;">
                                    <h4 class="my-1">You are ready!</h4>
                                    <p class="my-1">Thank you, you have taken the first step to achieve your financial wellness.</p>
                                    <p class="my-1">It is important that you take action, we have seen that 90% of people who take immediate action are those who achieve their goals, those who leave it for later usually do not meet them.</p>
                                    <p class="my-1">We want you to be part of the percentage that does achieve them!.</p>
                                    <p class="my-1">Remember that finances do not have to be complicated, <span class="fw-bolder">don't forget to enjoy the journey.</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-4">
                <div class="col-md-6 mx-auto m-2">
                    <div class="row">
                        <div class="col-10 mx-auto">
                            <div class="d-grid gap-2 col-md-8 mx-auto">
                                <button class="btn btn-primary btn-descargar" type="button" (click)="print();" *ngIf="lang == 'ES'">Descargar reporte</button>
                                <button class="btn btn-primary btn-descargar" type="button" (click)="print();" *ngIf="lang == 'PT'">Download informe</button>
                                <button class="btn btn-primary btn-descargar" type="button" (click)="print();" *ngIf="lang == 'EN'">Download report</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-4">
                <div class="col-md-10 mx-auto m-2">
                    <div class="d-flex align-items-start">
                        <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <button class="nav-link"
                                id="v-pills-tab-{{$index}}"
                                data-bs-toggle="pill"
                                [attr.data-bs-target]="'#tab-'+$index" type="button" role="tab"
                                aria-controls="v-pills"
                                aria-selected="true"
                                *ngFor="let i of result.calculo.lstCalculo; index as $index">
                                <fa-icon [icon]="faSquare" *ngIf="i.categoria != 'Disfrutar'
                                                                    && i.categoria != 'Optimizo'
                                                                    && i.categoria != 'Conciencia'
                                                                    && i.categoria != 'Planeo'
                                                                    && i.categoria != 'Control'
                                                                    && i.categoria != 'Protección'"></fa-icon>
                                <fa-icon [icon]="faShoePrints" *ngIf="i.categoria == 'Disfrutar'"></fa-icon>
                                <fa-icon [icon]="faPlaneArrival" *ngIf="i.categoria == 'Planeo'"></fa-icon>
                                <fa-icon [icon]="faCoins" *ngIf="i.categoria == 'Optimizo'"></fa-icon>
                                <fa-icon [icon]="faMapLocationDot" *ngIf="i.categoria == 'Conciencia'"></fa-icon>
                                <fa-icon [icon]="faThumbsUp" *ngIf="i.categoria == 'Control'"></fa-icon>
                                <fa-icon [icon]="faShieldHalved" *ngIf="i.categoria == 'Protección'"></fa-icon>
                                {{ i.categoriaDesc }}
                            </button>
                        </div>
                        <div class="tab-content" id="v-pills-tabContent">
                            <div class="tab-pane fade show" id="tab-{{$index}}" role="tabpanel"
                                aria-labelledby="v-pills-tab"
                                *ngFor="let i of result.calculo.lstCalculo; index as $index">
                                <h5 class="fw-bolder">
                                    <fa-icon [icon]="faSquare" [ngClass]="{'danger-l': i.color === 'rojo', 'warning-l': i.color === 'amarillo', 'ok-l': i.color === 'verde'}"></fa-icon>
                                    {{i.categoriaDesc}}
                                </h5>
                                <div [innerHTML]="i.text" style="text-align: justify;"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
-->
