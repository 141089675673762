import { Component, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { faSquare, faShoePrints, faPlaneArrival, faCoins, faMapLocationDot, faThumbsUp, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {

  public barChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          //maxRotation: 0,
          //minRotation: 0,
          font: {
            size: 20,
          },
          color: "#004150"
        },
      },
      y: {
        min: 10,
        max: 120,
        display: false
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value, ctx) => {
          let percentage = value + "%";
          return percentage;
        },
        color: '#004150',
        font: {
          size: 25,
        },
      }
    }
  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [
    DataLabelsPlugin
  ];
  public barChartData = {
    labels: [],
    datasets: [
      { data: [], backgroundColor: '#F2A640' }
    ]
  };
  

  public canvasWidth = 250;
  public needleValue = 0;
  public centralLabel = '';
  public options = {
      hasNeedle: true,
      needleColor: 'gray',
      needleUpdateSpeed: 1000,
      arcColors: ['#7a2931', '#dba032', '#2e7d32'],
      rangeLabel: ['0', '100'],
      needleStartValue: 50,
      arcDelimiters: [50, 90],
  }

  result: any = {};
  faSquare = faSquare;
  faShoePrints = faShoePrints;
  faPlaneArrival = faPlaneArrival;
  faCoins = faCoins;
  faMapLocationDot = faMapLocationDot;
  faThumbsUp = faThumbsUp;
  faShieldHalved = faShieldHalved;

  disfruto: any = {};
  dondellegar: any = {};
  optimizo: any = {};
  dondeEstoy: any = {};
  gestiono: any = {};
  protegido: any = {};
  lang = '';

  constructor(private router: Router,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    let _dataReport = JSON.parse(localStorage.getItem('dataReport'));
    let _personalData = JSON.parse(localStorage.getItem('personalData'));
    this.lang = _personalData.idioma;
    this.result = _dataReport.ansFW;
    this.needleValue = this.result.calculo.scoreBienestarFinanciero;
    this.barChartData.datasets[0].data = _dataReport.chart.data;
    this.barChartData.labels = _dataReport.chart.labels;
    this.disfruto = this.result.calculo.lstCalculo.find( el => el.categoria == 'Disfrutar');
    this.dondellegar = this.result.calculo.lstCalculo.find( el => el.categoria == 'Planeo');
    this.optimizo = this.result.calculo.lstCalculo.find( el => el.categoria == 'Optimizo');
    this.dondeEstoy = this.result.calculo.lstCalculo.find( el => el.categoria == 'Conciencia');
    this.gestiono = this.result.calculo.lstCalculo.find( el => el.categoria == 'Control');
    this.protegido = this.result.calculo.lstCalculo.find( el => el.categoria == 'Protección');
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      this.callprint();      
    }, 5000);
  }

  callprint(){
    setTimeout(() => {
      window.print();
      this.router.navigate(['/report']);
    }, 500);
  }


}
